<template>
  <dashboard-topbar />

  <div class="mt-8 all">
    <input
      type="text"
      v-model="filter"
      placeholder="Search by name or date"
      class="w-full px-4 py-4 rounded border border-gray-300 focus:border-blue-500"
      @keyup="filteredProductions"
    />
    <table
      class="table-auto w-full min-w-full bg-white mt-3"
      v-if="allProducts.length > 0"
    >
      <thead class="text-white">
        <tr>
          <th width="80">#</th>
          <th>Category</th>
          <th>Unit Price</th>
          <th>Total units</th>
          <th>Added on</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="production in productions"
          :key="production.id"
          :class="{ 'bg-blue-100': production.id % 2 === 0 }"
        >
          <td width="80">
            <img
              :src="production.image"
              alt=""
              @error="$imageurlalt()"
              class="item"
            />
          </td>
          <td class="px-4 py-2">{{ production.productCategory.name }}</td>
          <td class="px-4 py-2">{{ production.price }} Rwf</td>
          <td class="px-4 py-2">{{ production.quantity }}</td>
          <td class="px-4 py-2">{{ production.createdAt }}</td>
          <td style="width: 200px">
            <button class="verify" @click="startSold(production)">
              <label for="">Mark as sold out</label>
            </button>
          </td>
          <td style="width: 200px">
            <button class="normal" @click="startEdit(production)">
              <label for="">Edit</label>
            </button>
            <button class="delete" @click="startDelete(production)">
              <label for="">Delete</label>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <confirm-model
      :modelTitle="'Delete Production'"
      :smallModel="true"
      v-if="deletePopupOpen"
      @closeModel="deletePopupOpen = false"
      @confirmModel="confirmDelete()"
      :displayText="'Are you sure you want to delete this record?'"
    ></confirm-model>
    <confirm-model
      :modelTitle="'Mark Production as sold out'"
      :smallModel="true"
      v-if="soldPopupOpen"
      @closeModel="soldPopupOpen = false"
      @confirmModel="confirmSold()"
      :displayText="
        'Are you sure you want to mark this ' +
        selectedItem.productCategory.name +
        ' production as sold out?'
      "
    ></confirm-model>
    <model
      :modelTitle="'Edit Production'"
      :largeModel="true"
      v-if="showEditPopup"
      @closeModel="showEditPopup = false"
    >
      <form @submit.prevent="confirmUpdate()" class="">
        <div class="form-group group-4">
          <div class="input-group">
            <label for="">Product</label>
            <select name="" id="" v-model="selectedItem.productCategory.id">
              <option value="">Select Product</option>
              <option
                v-for="product in productCategories"
                :key="product.id"
                :value="product.id"
              >
                {{ product.name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="">Quantity</label>
            <input
              type="text"
              v-model="selectedItem.quantity"
              class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Production"
            />
          </div>
          <div class="input-group">
            <label for="">Product Unit</label>
            <!-- kg, littres, Tons, boxess, dozens, crates, pieces, grams -->
            <select name="" id="" v-model="selectedItem.units">
              <option value="">Select Unit</option>
              <option value="Kg">Kg</option>
              <option value="Littres">Littres</option>
              <option value="Tons">Tons</option>
              <option value="Boxes">Boxes</option>
              <option value="Dozens">Dozens</option>
              <option value="Crates">Crates</option>
              <option value="Pieces">Pieces</option>
              <option value="Grams">Grams</option>
            </select>
          </div>
          <div class="input-group">
            <label for="">Unit Price</label>
            <input
              type="text"
              v-model="selectedItem.price"
              class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Price"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="">Description</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="4"
              v-model="selectedItem.description"
              placeholder="Description"
            ></textarea>
          </div>
          <div class="input-group">
            <label for="">Image</label>
            <o-upload
              :multiple="false"
              :uploadUrl="'files/upload'"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>

        <button type="submit" class="submit">Save</button>
      </form>
    </model>
  </div>
</template>
  
  <script>
export default {
  name: "ProductionView",
  data() {
    return {
      productions: [],
      allProducts: [],
      filter: "",
      deletePopupOpen: false,
      selectedItem: {},
      showEditPopup: false,
      productCategories: [],
      soldPopupOpen: false,
    };
  },
  methods: {
    getMyProducts() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_PRODUCTS", { token: this.$getToken() })
        .then((res) => {
          this.productions = res.data.data;
          this.allProducts = [...this.productions];
          this.$stopLoader();
        });
    },
    filteredProductions() {
      this.productions = this.allProducts.filter((production) => {
        return (
          production.productCategory.name
            .toLowerCase()
            .includes(this.filter.toLowerCase()) ||
          production.createdAt.toLowerCase().includes(this.filter.toLowerCase())
        );
      });
    },
    getProductCategories() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PRODUCTSCATEGORIES", { token: this.$getToken() })
        .then((res) => {
          this.productCategories = res.data.data;
          this.$stopLoader();
        });
    },
    startEdit(production) {
      this.selectedItem = { ...production };
      if (this.selectedItem.quantity.split(" ")[1] == "null") {
        this.selectedItem.units = "Kg";
        this.selectedItem.quantity = this.selectedItem.quantity.split(" ")[0];
      } else {
        this.selectedItem.units = this.selectedItem.quantity.split(" ")[1];
        this.selectedItem.quantity = this.selectedItem.quantity.split(" ")[0];
      }
      this.showEditPopup = true;
    },
    startDelete(production) {
      this.selectedItem = production;
      this.deletePopupOpen = true;
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_PRODUCT", {
          token: this.$getToken(),
          id: this.selectedItem.id,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Production deleted successfully",
            });
            this.deletePopupOpen = false;
            this.getMyProducts();
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: "Failed to delete production",
            });
          }
        });
    },
    confirmUpdate() {
      let updateItem = {
        product_category: this.selectedItem.productCategory.id,
        product_description: this.selectedItem.description,
        production_quantity: this.selectedItem.quantity,
        product_price: this.selectedItem.price,
        image: this.selectedItem.image,
        units: this.selectedItem.units,
      };
      this.$startLoader();
      this.$store
        .dispatch("EDIT_PRODUCT", {
          token: this.$getToken(),
          product_id: this.selectedItem.id,
          data: updateItem,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Production updated successfully",
            });
            this.showEditPopup = false;
            this.getMyProducts();
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: "Failed to update production",
            });
          }
        });
    },
    startSold(production) {
      this.selectedItem = production;
      this.soldPopupOpen = true;
    },
    confirmSold() {},
  },
  mounted() {
    this.getMyProducts();
    this.getProductCategories();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.all {
  margin: 2rem;
}

.production-view {
  width: 80%;
  margin: 0 auto;
}

.production-table {
  width: 80%;
}

th {
  background-color: $blue;
  color: white;
}

.production-table th,
.production-table td {
  border: 1px solid black;
  padding: 5px;
}
form {
  width: 100% !important;
}
</style>
  
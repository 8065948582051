import axios from 'axios'
import { API_URL, AGRIAPI_URL } from '../variables';
const GET_COUNTRIES_URL = '/countries';
const ADD_PRODUCT_URL = AGRIAPI_URL + '/products';
const GET_ALL_PRODUCTS_URL = AGRIAPI_URL + '/products/all';
const GET_MY_PRODUCTS_URL = AGRIAPI_URL + '/products/my-products';
const GET_PRODUCT_BY_ID_URL = AGRIAPI_URL + '/products/'
const GET_COOPERATIVE_PRODUCTS_URL = AGRIAPI_URL + '/cooperatives/selling/products';
const GET_PRODUCT_CATEGORY_BY_ID_URL = AGRIAPI_URL + '/categories/'
const EDIT_PRODUCT_BY_ID_URL = AGRIAPI_URL + '/products/update/'
const DELETE_PRODUCT_BY_ID_URL = AGRIAPI_URL + '/products/'
const products = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_PRODUCT']: ({ commit }, payload) => {
            return axios({
                url: ADD_PRODUCT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ALL_PRODUCTS']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_PRODUCTS_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_MY_PRODUCTS']: ({ commit }, payload) => {
            return axios({
                url: GET_MY_PRODUCTS_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_COOPERATIVE_PRODUCTS']: ({ commit }, payload) => {
            return axios({
                url: GET_COOPERATIVE_PRODUCTS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_PRODUCT_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_PRODUCT_BY_ID_URL + payload.product_id,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_PRODUCT_CATEGORY_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_PRODUCT_CATEGORY_BY_ID_URL + payload.id,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['EDIT_PRODUCT']: ({ commit }, payload) => {
            return axios({
                url: EDIT_PRODUCT_BY_ID_URL + payload.product_id,
                method: "PUT",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_PRODUCT']: ({ commit }, payload) => {
            return axios({
                url: DELETE_PRODUCT_BY_ID_URL + payload.id,
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default products;
<template>
    <div class="card">
      <apexchart
        type="pie"
        :options="moptions.chartOptions"
        :series="moptions.series"
        class="apexchart"
      ></apexchart>
    </div>
  </template>
  
  <script>
  import ApexCharts from "vue-apexcharts";
  
  export default {
    name: "PieChart",
    data(){
        return{
            moptions:{
                series: [],
                chartOptions: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: [],
                responsive: [
                    {
                    breakpoint: 480,
                    options: {
                        chart: {
                        width: 200,
                        },
                        legend: {
                        position: "bottom",
                        },
                    },
                    },
                ],
                },
            }
        }
    },
    components: {
      ApexCharts,
    },
  
    props: {
      payloadData: Array,
    },
  

    mounted(){
        this.payloadData.forEach((payload => {
            this.moptions.chartOptions.labels.push(payload.productCategory.name)
            this.moptions.series.push(payload.quantity)

        }))
    }
  };
  </script>
  
  <style scoped>
  .apexchart {
    width: 400px;
    height: 400px;
  }
  </style>
  
<template>
    <dashboard-topbar />
    <div class="container mx-auto p-4">
      <div class="flex justify-between mb-4">
        <h2 class="text-xl font-bold mt-8">Products you can order</h2>
        <span class="text-blue-500 font-bold">Cart: {{ cart.length }}</span>
      </div>
  
      <div class="flex flex-wrap">
    <div v-for="product in products" :key="product.id" class="w-1/3 p-4">
      <div class="card bg-white shadow-lg p-4 text-center">
            <h1 class="card-title font-semibold text-muted">{{ product.name }}</h1>
            <img :src="getRandomImage()" class="card-img-top" alt="Product Image" />
            <div class="card-body">
              <p class="card-text"> Price: $ {{ product.price }}</p>
              <p class="card-text">Owner: {{ product.postedBy }}</p>
              <button @click="addToCart(product)" class="btn btn-primary">Add to Cart</button>
            </div>
          </div>
        </div>
      </div>
  
      <div v-if="cart.length > 0">
        <h2 class="text-xl font-bold mb-4">Cart</h2>
        <div v-for="product in cart" :key="product.id" class="mb-4 w-100 p-4 border">
          <h1>{{ product.name }}</h1>
          <p><span>Unit price:</span>{{ product.price }}</p>
          <p>Owner: {{ product.postedBy }}</p>
          <button @click="removeFromCart(product)" class="ml-4 rem btn btn-danger text-white px-3 py-1 rounded">Remove</button>
          <button type="submit" @click="placeOrder" class="btn btn-primary ml-4">Place Order</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        products: [
          { id: 1, name: "Product 1", price: 10, postedBy: "Hortance" },
          { id: 2, name: "Product 2", price: 20, postedBy: "Irakoze" },
          { id: 3, name: "Product 3", price: 20, postedBy: "Marie" },
        ],
        cart: [],
        orderedBy: [],
      };
    },
    methods: {
      addToCart(product) {
        this.cart.push(product);
      },
      removeFromCart(product) {
        this.cart = this.cart.filter(p => p !== product);
      },
      placeOrder() {
        if (this.cart.length === 0) {
          alert("Cart is empty. Add products before placing an order.");
          return;
        }
  
        console.log("Placing order:", { name: this.name, email: this.email, products: this.cart });
      },
      getRandomImage() {
      const randomId = Math.floor(Math.random() * 1000); 
      return `https://picsum.photos/seed/${randomId}/300/200`; 
    },
    },

    getAllProducts(){
        //This is for Leo
    },
    mounted() {
      this.$getUser();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/main.scss";
  
  .button {
    background-color: $blue !important;
    color: white;
    padding: 5px;
  }
  .rem{
    background-color:red !important;
  }
  </style>
  
<template>
      <dashboard-topbar/>

    <div class="flex justify-center items-center h-screen">
      <form class=" p-16 border rounded shadow-lg">
        <h2 class="text-xl font-bold mb-4">Add Farm</h2>
        <div class="mb-4">
          <label for="name" class="block font-bold mb-2">Name</label>
          <input v-model="name" type="text" id="name" class="w-full px-4 py-2 border rounded" required>
        </div>
        <div class="mb-4">
          <label for="owner" class="block font-bold mb-2">Owner</label>
          <input v-model="owner" type="text" id="owner" class="w-full px-4 py-2 border rounded" required>
        </div>
        <div class="mb-4">
          <label for="count" class="block font-bold mb-2">Count</label>
          <input v-model="count" type="text" id="count" class="w-full px-4 py-2 border rounded" required>
        </div>
        <div class="mb-4">
          <label for="description" class="block font-bold mb-2">Description</label>
          <textarea v-model="description" id="description" class="w-full px-4 py-2 border rounded" required></textarea>
        </div>
        <div class="mb-4">
          <label for="location" class="block font-bold mb-2">Location</label>
  

          <div class="form-group group-4">
            <select
              name="province"
              id=""
              v-model="location.province"
              @change="getDistricts(location.province)"
            >
              <option value="">{{ $txt("Province") }}</option>
              <option
                v-for="province in provinces"
                :key="province.provinceId"
                :value="province.provinceId"
              >
                {{ province.provinceName }}
              </option>
            </select>
            <select
              name="district"
              id=""
              v-model="location.district"
              @change="getSectors(location.district)"
            >
              <option value="">{{ $txt("District") }}</option>
              <option
                v-for="district in districts"
                :key="district.districtId"
                :value="district.districtId"
              >
                {{ district.districtName }}
              </option>
            </select>
            <select
              name="sector"
              id=""
              v-model="location.sector"
              @change="getCells(location.sector)"
            >
              <option value="">{{ $txt("Sector") }}</option>
              <option
                v-for="sector in sectors"
                :key="sector.sectorId"
                :value="sector.sectorId"
              >
                {{ sector.sectorName }}
              </option>
            </select>
            <select name="cell" id="" v-model="location.cell">
              <option value="">{{ $txt("Cell") }}</option>
              <option
                v-for="cell in cells"
                :key="cell.cellId"
                :value="cell.cellName"
              >
                {{ cell.cellName }}
              </option>
            </select>
          </div>        
        </div>
        <div class="text-center">
          <button type="submit" class="bg-blue-900 text-white px-4 py-2 rounded">Submit</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        name: '',
        owner: '',
        count: '',
        description: '',
        location: {
         province:'',
         district:'',
         sector:'',
         cell:'',
        },
        provinces: [],
        districts: [],
        sectors: [],
        cells: [],
      };
    },
    methods: {
      addCooperative() {
      },
      getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    },
    mounted() {
        this.getProvinces();

    }
  };
  </script>
  
  <style lang="scss">
    @import "@/assets/scss/main.scss";

  form{
    width: 60%;
  }
  button{
    background-color: $blue;
  }
  </style>
  
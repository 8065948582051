import axios from 'axios'
import { API_URL, AGRIAPI_URL } from '../variables';
const GET_COUNTRIES_URL = '/countries';
const GET_PRODUCTSCATEGORIES_URL = AGRIAPI_URL + '/products/categories';
const GET_FARMINGSECTORS_URL = AGRIAPI_URL + '/sectors/all';

const productCategories = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_PRODUCTSCATEGORIES']: ({ commit }, payload) => {
            return axios({
                url: GET_PRODUCTSCATEGORIES_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_FARMINGSECTORS']: ({ commit }, payload) => {
            return axios({
                url: GET_FARMINGSECTORS_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default productCategories;
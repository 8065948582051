<template>
  <dashboard-topbar />
  <div class="container mx-auto p-4">
    <h2 class="text-2xl font-bold mb-4">Cooperative Members</h2>
    <div class="flex items-center">
      <input
        type="checkbox"
        v-model="filter.approved"
        class="mr-2"
      />
      <label>Approved</label>

      <input
        type="checkbox"
        v-model="filter.pendingApproval"
        class="mr-2"
      />
      <label>Pending approval</label>
    </div>
    <input
      type="text"
      v-model="filter.search"
      class="w-full px-4 py-2 rounded border border-gray-300 focus:border-blue-500"
      placeholder="Search members..."
      @keyup="filteredMembers"
    />

    <div>
      <div v-if="members.length === 0" class="text-center">
        No members found.
      </div>

      <div v-else>
        <div
          v-for="member in members"
          :key="member.id"
          class="mb-4 p-4 border rounded shadow-lg"
        >
          <h3 class="text-xl font-bold mb-2">{{ member.user.names }}</h3>
          <p><strong>Email:</strong> {{ member.user.email }}</p>
          <p><strong>Phone:</strong> {{ member.user.phone }}</p>
          <p
            v-if="member.status === 'APPROVED'"
            class="text-green-500 font-semibold"
          >
            Status: APPROVED
          </p>
          <p v-else class="text-red-500 font-semibold">
            Status: Pending approval
          </p>

          <div class="flex justify-end mt-4">
            <button @click.prevent="openMemberRoleModel = true">
              <span class="text-white">{{ $txt("Change Roles") }} </span>
            </button>
            <button
              v-if="member.status === 'PENDING'"
              @click="acceptRequest(member)"
            >
              <span class="text-white">{{ $txt("Accept Request") }} </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <model
      :modelTitle="'Change Role'"
      :smallModel="true"
      v-show="openMemberRoleModel"
      @closeModel="openMemberRoleModel = false"
    >
      <ChangeRole />
    </model>
  </div>
</template>

<script>
import ChangeRole from "../site/users/ChangeRole.vue";
export default {
  data() {
    return {
      loading: true,
      members: [],
      allMembers: [],
      filter: {
        approved:false,
        pendingApproval:false,
        search:'',
      },
      openMemberRoleModel: false,
      activeCooperative: null,
    };
  },

  methods: {
    getMembers() {
      this.$startLoader();
      this.$store
        .dispatch("GET_COOPERATIVE_MEMBERS", {
          token: this.$getToken(),
          data: { cooperative_id: this.$route.params.id },
        })
        .then((res) => {
          this.members = res.data.data;
          this.allMembers = res.data.data;

          this.$stopLoader();
          this.$notify({
            title: "Success",
            type: "success",
            text: res.data.message,
          });
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          }
        });
    },
    filteredMembers() {
    this.members = this.allMembers.filter((member) => {
      const isApproved = member.status === 'APPROVED';
      const isPendingApproval = member.status === 'PENDING_APPROVAL';

      return (
        (isApproved && this.filter.approved) ||
        (isPendingApproval && this.filter.pendingApproval) ||
        member.user.names.toLowerCase().includes(this.filter.search.toLowerCase()) ||
        member.user.email.toLowerCase().includes(this.filter.search.toLowerCase()) ||
        member.user.phone.toLowerCase().includes(this.filter.search.toLowerCase()) ||
        member.status.toLowerCase().includes(this.filter.search.toLowerCase())
      );
    });
    },
    acceptRequest(){
      this.$startLoader();
      this.$store
        .dispatch("ACCEPT_REQUEST", {
          token: this.$getToken(),
          data: { cooperative_id: this.$route.params.id },
        })
        .then((res) => {
          this.$stopLoader();
          this.$notify({
            title: "Success",
            type: "success",
            text: res.data.message,
          });
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          }
        });
    },
    },

  mounted() {
    this.getMembers();
  },
  components: { ChangeRole },

  computed: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/main.scss";
.input-small {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 40px;
}

button {
  background-color: $blue !important;
  border-radius: 4px;
  padding: 10px 20px;
  width: 200px;
  margin: 40px;
  cursor: pointer;
}
</style>

import { AGRIAPI_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'

const MAKE_ORDER_URL = AGRIAPI_URL +'/orders'
const GET_ALL_ORDERS_URL = AGRIAPI_URL +'/orders/all'
const GET_ORDERS_BY_ID_URL = AGRIAPI_URL +'/orders/'
const GET_MY_ORDERS_URL= AGRIAPI_URL +'/orders/my-orders'
const APPROVE_ORDER_URL = AGRIAPI_URL +'/orders/approve/'
const GET_ASSIGNED_ORDERS_URL = AGRIAPI_URL +'/orders/assigned'
const orders = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['MAKE_ORDER']: ({ commit }, payload) => {
            return axios({
                url: MAKE_ORDER_URL,
                method: "POST",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
 
        ['GET_ALL_ORDERS']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_ORDERS_URL,
                method: "GET",
                data: payload,
                headers:{
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ORDERS_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_ORDERS_BY_ID_URL,
                method: "GET",
                data: payload,
                headers:{
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        
        ['GET_MY_ORDERS']: ({ commit }, payload) => {
            return axios({
                url: GET_MY_ORDERS_URL,
                method: "GET",
                data: payload,
                headers:{
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['APPROVE_ORDER']: ({ commit }, payload) => {
            return axios({
                url: APPROVE_ORDER_URL,
                method: "PUT",
                data: payload,
                headers:{
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ASSIGNED_ORDERS']: ({ commit }, payload) => {
            return axios({
                url: GET_ASSIGNED_ORDERS_URL,
                method: "GET",
                data: payload,
                headers:{
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default orders;
<template>
  <div class="ViewOrder">
    <div
      class="dialog"
      :class="dialogActive ? 'active' : ''"
      v-click-out-side="customMethod2"
    >
      <div class="dialog-header">
        <h1>Order Details</h1>
        <button @click="closePopup">&times;</button>
      </div>
      <div class="dialog-body">
        <div class="left">
          <div class="product-info">
            <div class="product-image">
              <img :src="order.product.image" alt="" @error="$imageurlalt()" />
            </div>
            <div class="product-details">
              <h1>{{ order.product.productCategory.name }}</h1>
              <p>{{ order.product.description }}</p>
              <div class="product-quantity">
                <span>Quantity</span>
                <span>{{ order.quantity }}</span>
              </div>
              <div class="product-price">
                <span>Price</span>
                <span>{{ order.product.price }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="middle order-info">
          <div class="order-date items">
            <span>Order Date</span>
            <p>{{ order.date }}</p>
          </div>
          <div class="order-status items">
            <span>Status</span>
            <p v-if="order.status == 'Pending'" class="pending">
              {{ order.status }}
            </p>
            <p v-if="order.status == 'Delivered'" class="delivered">
              {{ order.status }}
            </p>
            <p v-if="order.status == 'Rejected'" class="rejected">
              {{ order.status }}
            </p>
          </div>
          <div class="client-data">
            <div class="header">
              <h1>About the client</h1>
            </div>
            <div class="data-list">
              <div class="order-client items">
                <span>Client</span>
                <p>{{ order.client.name }}</p>
              </div>
              <div class="order-client items">
                <span>Phone</span>
                <p>{{ order.client.phone }}</p>
              </div>
              <div class="order-client items">
                <span>Email</span>
                <p>{{ order.client.email }}</p>
              </div>
              <div class="order-client items">
                <span>Address</span>
                <p>{{ order.client.address }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="right calculations">
          <div class="prices">
            <span>Unit Price</span>
            <p>{{ order.product.price }} Rwf</p>
          </div>
          <div class="prices">
            <span>Available Quantity</span>
            <p>{{ order.product.quantity }}</p>
          </div>
          <div class="prices">
            <span>Ordered Quantity</span>
            <p>{{ order.quantity }}</p>
          </div>
          <div class="balance">
            <span>Projected Quantity Balance</span>
            <p
              :class="order.product.quantity - order.quantity < 0 ? 'red' : ''"
            >
              {{ order.product.quantity - order.quantity }}
            </p>
          </div>
          <div class="total-price">
            <span>Projected Price</span>
            <p>{{ order.product.price * order.quantity }} Rwf</p>
          </div>
          <div class="actions" v-if="order.status == 'Pending'">
            <button @click="showConfirmPopup = true">Confirm Delivery</button>
            <button @click="showRejectPopup = true">Reject Order</button>
          </div>
        </div>
      </div>
      <model
        :modelTitle="'Reject order'"
        v-if="showRejectPopup"
        @closeModel="
          showRejectPopup = false;
          clickCount = 0;
          message = '';
        "
      >
        <form @submit.prevent="confirmReject()" style="width: 100%">
          <div class="form-group">
            <div class="input-group">
              <label for="">Message to client:</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="4"
                v-model="message"
                placeholder="Message"
              ></textarea>
            </div>
          </div>
          <button type="submit" class="submit">Confirm rejection</button>
        </form>
      </model>
      <model
        :modelTitle="'Confirm delivery'"
        v-if="showConfirmPopup"
        @closeModel="
          showConfirmPopup = false;
          clickCount = 0;
        "
      >
        <form @submit.prevent="confirmDelivery()" style="width: 100%">
            <div class="form-group">
              <div class="input-group">
                <label for=""
                  >Deduct {{ order.quantity }} quantity from
                  {{ order.product.productCategory.name }} production</label
                >
                <select name="" id="" v-model="deduct">
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
              <div class="input-group">
                <label for="">Message to client:</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="4"
                  v-model="message"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <button type="submit" class="submit">Confirm Delivery</button>
        </form>
      </model>
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "ViewOrder",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogActive: false,
      clickCount: 0,
      showRejectPopup: false,
      showConfirmPopup: false,
      message: "",
      deduct: 'Yes'
    };
  },
  directives: {
    clickOutSide,
  },
  methods: {
    closePopup() {
      this.dialogActive = false;
      setTimeout(() => {
        this.$emit("closePopup");
      }, 500);
    },
    customMethod2() {
      this.clickCount++;
      if (this.clickCount > 1) {
        this.closePopup();
      }
    },
    confirmReject() {
      this.$emit("rejectOrder", {
        message: this.message,
        order: this.order,
      });
      this.showRejectPopup = false;
    },
    confirmDelivery() {
      this.$emit("confirmDelivery", {
        order: this.order,
      });
      this.showConfirmPopup = false;
    },
  },
  mounted() {
    // setTimeout
    setTimeout(() => {
      this.dialogActive = true;
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ViewOrder {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .dialog {
    width: 100%;
    height: 60%;
    background-color: #eee;
    position: relative;
    bottom: -60%;
    left: 0;
    transition: all 0.5s ease-in-out;
    &.active {
      bottom: 0;
    }
    .dialog-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 20px;
      border-bottom: 1px #e5e5e5 solid;
      h1 {
        font-size: 20px;
        font-weight: 600;
      }
      button {
        background-color: transparent;
        border: none;
        font-size: 30px;
        font-weight: 400;
        cursor: pointer;
      }
    }
    .dialog-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem;
      padding: 1rem 20px;
      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px #b6b6b6 solid;
        .product-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .product-image {
            width: 100%;
            height: 200px;
            overflow: hidden;
            border-radius: 10px;
            border: 1px #cccccc solid;
            background: #e4e4e4;
            padding: 5px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }
          .product-details {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h1 {
              font-size: 20px;
              font-weight: 600;
              margin-top: 5px;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #6a6a6a;
            }
            .product-quantity {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              span {
                font-size: 16px;
                font-weight: 400;
                color: #4b4b4b;
              }
            }
            .product-price {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              span {
                font-size: 16px;
                font-weight: 400;
                color: #4b4b4b;
              }
            }
          }
        }
      }
      .middle {
        width: 100%;
        align-items: center;
        border-right: 1px #b6b6b6 solid;
        .items {
          display: grid;
          grid-template-columns: 1fr 1fr;
          span {
            font-size: 20px;
            font-weight: 400;
            color: #707070 !important;
          }
          p {
            font-size: 18px;
            font-weight: 400;
            color: #000000 !important;
            &.pending {
              color: #c58622 !important;
            }
            &.delivered {
              color: #256d2f !important;
            }
            &.rejected {
              color: #a52424 !important;
            }
          }
        }
        .client-data {
          width: 100%;
          margin-top: 1rem;
          .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1 {
              font-size: 20px;
              font-weight: 600;
              color: #4b4b4b;
            }
          }
          .data-list {
            width: 100%;
            margin-top: 1rem;
            .order-client {
              display: grid;
              grid-template-columns: 1fr 1fr;
              span {
                font-size: 18px;
                font-weight: 400;
                color: #707070 !important;
              }
              p {
                font-size: 16px;
                font-weight: 400;
                color: #000000 !important;
              }
            }
          }
        }
      }
      .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.calculations {
          width: 100%;
          .prices {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: 0.9rem;
              font-weight: 400;
              color: #707070 !important;
            }
            p {
              font-size: 1.25rem;
              font-weight: 400;
              color: #000000 !important;
            }
          }
          .balance {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1rem;
            span {
              font-size: 0.9rem;
              font-weight: 400;
              color: #707070 !important;
            }
            p {
              font-size: 1.25rem;
              font-weight: 400;
              color: #000000 !important;
              &.red {
                color: #a52424 !important;
              }
            }
          }
          .total-price {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px #b6b6b6 solid;
            margin-top: 1rem;
            padding-top: 0.5rem;
            span {
              font-size: 1.5rem;
              font-weight: 400;
              color: #707070 !important;
            }
            p {
              font-size: 2rem;
              font-weight: 400;
              color: #256d2f !important;
            }
          }
          .actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1rem;
            button {
              width: 200px;
              height: 60px;
              border-radius: 5px;
              border: none;
              font-size: 16px;
              font-weight: 400;
              cursor: pointer;
              &:first-child {
                background-color: #418043;
                color: #fff;
              }
              &:last-child {
                background-color: #7e3732;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="profile">
    <!-- Random Picture on the Left -->
    <div class="left">
      <img
        v-if="randomImage"
        :src="randomImage"
        alt="Random"
        class="object-cover w-full h-auto rounded-lg"
      />
    </div>

    <!-- Cooperative Details on the Right -->
    <div class="right">
      <div class="right-top">
        <p>
          <strong>Name:</strong> <label>{{ cooperativeName }}</label>
        </p>
        <p v-if="president">
          <strong>President:</strong> <label>{{ president }}</label>
        </p>
        <p v-else>
          <strong>Owner:</strong> <label>{{ added.names }}</label>
        </p>
        <p>
          <strong>Production:</strong><label> {{ production }}</label>
        </p>
        <p>
          <strong>Location:</strong><label>{{ location }}</label>
        </p>
      </div>
      <!-- <p class="description">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsum quam
        repellat dolorum atque libero similique recusandae fuga esse quaerat at
        assumenda aut sequi harum ea quae eaque hic, dolores deleniti?
      </p> -->
      <div class="d-flex">
        <router-link
          :to="{ name: 'AddProduction', params: { id: cooperative.id } }"
        >
          <button class="text-white px-4 py-2 mt-4 rounded">
            Add Production
          </button>
        </router-link>

        <router-link
          :to="{
            name: 'CooperativeMembers',
            params: { id: cooperative.id },
          }"
          ><button class="text-white px-4 py-2 mt-4 rounded">
            View Members
          </button></router-link
        >
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    cooperativeName: String,
    president: String,
    production: String,
    location: String,
    added: Object,
    cooperative: Object,
  },
  data() {
    return {
      randomImage: `https://source.unsplash.com/300x200/?nature&${Math.random()}`,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.profile {
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  align-items: center;
  .left {
    width: 300px;
    height: fit-content;
    margin-right: 1rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 1rem;
    .right-top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      p {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        strong {
          font-weight: 500;
          font-size: 0.9rem;
        }
        label {
          font-size: 1.3rem;
          font-weight: 700;
        }
      }
    }
    button {
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;
      background-color: $blue;
    }
    .description {
      margin-top: 5px;
      margin-bottom: 5px;
      color: #595959;
    }
  }
}
</style>
  
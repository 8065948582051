<template>
  <div class="viewCooperative">
    <dashboard-topbar :pageTitle="'Cooperatives you belong to'" />
    <div class="page-content">
      <div class="top-content">
        <div class="search">
          <input
            type="text"
            v-model="search"
            placeholder="Search"
            @keyup="searchData"
          />
        </div>
        <div class="right">
          <router-link class="add-btn" :to="{ name: 'addCooperative' }"
            >Add Cooperative</router-link
          >
        </div>
      </div>
      <div class="coop-widget">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Card
            v-for="(item, index) in data"
            :key="index"
            :data="item"
            :notMember="fasle"
            @requestToJoin="
              requestPopupOpen = true;
              activeCooperative = item.id;
            "
          />
        </div>
      </div>
    </div>
    <confirm-model
      :modelTitle="'Request to join'"
      :smallModel="true"
      v-if="requestPopupOpen"
      @closeModel="requestPopupOpen = false"
      @confirmModel="confirmRequest()"
      :displayText="'Are you sure you want to request to join this cooperative?'"
    ></confirm-model>
  </div>
</template>

<script>
import Card from "../components/Card.vue";

export default {
  name: "ViewCooperative",
  components: {
    Card,
  },
  data() {
    return {
      data: [],
      allData: [],
      search: "",
      requestPopupOpen: false,
      activeCooperative: null,
    };
  },
  methods: {
    searchData() {
      this.data = this.allData.filter(
        (item) =>
          item.cooperative_names.toLowerCase().includes(this.search.toLowerCase()) ||
          item.district.toLowerCase().includes(this.search.toLowerCase()) ||
          item.mainProduct.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    getCooperatives() {
      this.$startLoader();
      this.$store
        .dispatch("GET_COOPERATIVES", { token: this.$getToken() })
        .then((res) => {
          this.allData = res.data.data;
          this.data = [...this.allData];
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          }
        });
    },
    confirmRequest() {
      this.$startLoader();
      this.$store
        .dispatch("JOIN_COOPERATIVE", {
          token: this.$getToken(),
          data: { cooperative_id: this.activeCooperative },
        })
        .then((res) => {
          this.$stopLoader();
          this.$notify({
            title: "Success",
            type: "success",
            text: res.data.message,
          });
          this.requestPopupOpen = false;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          }
        });
    },
  },
  mounted() {
    this.data = this.allData;
    this.getCooperatives();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.page-content {
  padding: 1rem 1rem;
  .coop-widget {
    margin: 1rem 0;
  }
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      .add-btn {
        padding: 0.5rem 1rem;
        background-color: $blue;
        color: #fff;
        border-radius: 5px;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
</style>

import axios from 'axios'
import { API_URL, AGRIAPI_URL } from '../variables';
const ADD_COOPERATIVE_URL = AGRIAPI_URL + '/cooperatives';
const GET_COOPERATIVES_URL = AGRIAPI_URL + '/cooperatives/my/cooperatives';
const GET_ALL_COOPERATIVES_URL = AGRIAPI_URL + '/cooperatives/all';
const GET_COOPERATIVE_BY_ID_URL = AGRIAPI_URL + '/cooperatives/';
const UPDATE_COOPERATIVE_URL = AGRIAPI_URL + '/cooperatives/update';
const DELETE_COOPERATIVE_URL = AGRIAPI_URL + '/cooperatives/remove';
const JOIN_COOPERATIVE_URL = AGRIAPI_URL + '/cooperatives/members/apply';
const GET_COOPERATIVE_MEMBERS_URL = AGRIAPI_URL + '/cooperatives/members';
const GET_COOPERATIVE_BY_ID = AGRIAPI_URL + '/cooperatives/';

const cooperatives = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_COOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: ADD_COOPERATIVE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_COOPERATIVES']: ({ commit }, payload) => {
            return axios({
                url: GET_COOPERATIVES_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ALL_COOPERATIVES']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_COOPERATIVES_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_COOPERATIVE_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_COOPERATIVE_BY_ID_URL + payload.data.cooperativeId,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['UPDATE_COOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: UPDATE_COOPERATIVE_URL,
                method: "PUT",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_COOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: DELETE_COOPERATIVE_URL,
                method: "DELETE",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['JOIN_COOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: JOIN_COOPERATIVE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_COOPERATIVE_MEMBERS']: ({ commit }, payload) => {
            return axios({
                url: GET_COOPERATIVE_MEMBERS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_COOPERATIVE_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_COOPERATIVE_BY_ID + payload.data.cooperative_id,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default cooperatives;
<template>
          <dashboard-topbar/>
          <h1>Production chart</h1>

  <div>
    <!-- <PieChart :payloadData="payloadData"></PieChart> -->
    
  </div>
</template>

<script>
import PieChart from "../components/PieChart.vue"
export default {
  name: "Dashboard",

  components: {
    PieChart,
  },

  data() {
    return {
      payloadData: [
        
      ],
      
    };
  },
  methods: {
  getMyProducts() {
    this.$startLoader();
    this.$store
      .dispatch("GET_MY_PRODUCTS", { token: this.$getToken() })
      .then((res) => {
        const products = res.data.data;

        // Group products by productCategory.name
        const groupedProducts = {};
        products.forEach(product => {
          const categoryName = product.productCategory.name;
          if (!groupedProducts[categoryName]) {
            groupedProducts[categoryName] = {
              productCategory: { name: categoryName },
              quantity: 0
            };
          }
          groupedProducts[categoryName].quantity += product.quantity;
        });

        this.payloadData = Object.values(groupedProducts).map(groupedProduct => ({
          productCategory: groupedProduct.productCategory,
          quantity: groupedProduct.quantity
        }));

        this.allProducts = [...this.payloadData];
        this.$stopLoader();
        console.log(this.payloadData);
      });
  },
},

  mounted() {
    this.getMyProducts()
  }
};
</script>

<style scoped>
/* Add custom CSS here */
</style>

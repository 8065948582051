<template>
  <div class="page-content">
    <dashboard-topbar :pageTitle="'Add Cooperative'" />

    <!-- <h1 class="text-xl font-bold text-center">Add Cooperative</h1> -->

    <form @submit.prevent="addCooperative" class="">
      <div class="form-group group-2">
        <div class="input-group">
          <label for="">Cooperative name</label>
          <input
            type="text"
            v-model="newCooperative.cooperative_name"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Name"
          />
        </div>
        <div class="input-group">
          <label for="">Phone</label>
          <input
            type="text"
            v-model="newCooperative.telephone_number"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Phone"
          />
        </div>
      </div>
      <div class="form-group group-3">
        <div class="input-group">
          <label for="">Email</label>
          <input
            type="text"
            v-model="newCooperative.email_address"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Email"
          />
        </div>

        <div class="input-group">
          <label for="">Main Product</label>
          <select name="" id="" v-model="newCooperative.product_category_id">
            <option value="">Select Product</option>
            <option
              v-for="product in productCategories"
              :key="product.id"
              :value="product.id"
            >
              {{ product.name }}
            </option>
          </select>
        </div>
        <div class="input-group">
          <label for="">Agricultural sector</label>
          <select name="" id="" v-model="newCooperative.sector_id">
            <option value="">Select Sector</option>
            <option
              v-for="category in farmingCategories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group group-2">
        <div class="input-group">
          <label for="">Productivity/year <span>Ex: 1000 eggs</span></label>
          <input
            type="text"
            v-model="newCooperative.production_capacity"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Production"
          />
        </div>
        <div class="input-group">
          <label for="">Number of members</label>
          <input
            type="number"
            v-model="newCooperative.number_of_members"
            min="1"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Membera"
          />
        </div>
      </div>

      <div class="form-group group-4">
        <!-- Province, Disctict, sector, cell -->
        <select
          name="province"
          id=""
          v-model="coopLocation.province"
          @change="getDistricts(coopLocation.province)"
        >
          <option value="">{{ $txt("Province") }}</option>
          <option
            v-for="province in provinces"
            :key="province.provinceId"
            :value="province.provinceId"
          >
            {{ province.provinceName }}
          </option>
        </select>
        <select
          name="district"
          id=""
          v-model="coopLocation.district"
          @change="getSectors(coopLocation.district)"
        >
          <option value="">{{ $txt("District") }}</option>
          <option
            v-for="district in districts"
            :key="district.districtId"
            :value="district.districtId"
          >
            {{ district.districtName }}
          </option>
        </select>
        <select
          name="sector"
          id=""
          v-model="coopLocation.sector"
          @change="getCells(coopLocation.sector)"
        >
          <option value="">{{ $txt("Sector") }}</option>
          <option
            v-for="sector in sectors"
            :key="sector.sectorId"
            :value="sector.sectorId"
          >
            {{ sector.sectorName }}
          </option>
        </select>
        <select name="cell" id="" v-model="newCooperative.cell">
          <option value="">{{ $txt("Cell") }}</option>
          <option
            v-for="cell in cells"
            :key="cell.cellId"
            :value="cell.cellName"
          >
            {{ cell.cellName }}
          </option>
        </select>
      </div>

      <button type="submit" class="submit">Save</button>
    </form>
  </div>
</template>
  
  <script>
export default {
  name: "addCooperative",
  data() {
    return {
      name: "",
      product: "",
      production: 0,
      coopLocation: {
        province: "",
        district: "",
        sector: "",
        cell: "",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      newCooperative: {
        cooperative_name: "",
        telephone_number: "",
        email_address: "",
        production_capacity: "",
        number_of_members: 0,
        province: "",
        district: "",
        sector: "",
        cell: "",
        sector_id: "",
        product_category_id: "",
      },
      productCategories: [],
      farmingCategories: [],
    };
  },

  methods: {
    addCooperative() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_COOPERATIVE", {
          token: this.$getToken(),
          data: this.newCooperative,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
            this.$router.push({ name: "viewCooperatives" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.message,
          });
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.newCooperative.province = this.provinces.find(
        (province) => province.provinceId == provinceId
      ).provinceName;
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.newCooperative.district = this.districts.find(
        (district) => district.districtId == districtId
      ).districtName;
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.newCooperative.sector = this.sectors.find(
        (sector) => sector.sectorId == sectorId
      ).sectorName;
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    getProductCategories() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PRODUCTSCATEGORIES", { token: this.$getToken() })
        .then((res) => {
          this.productCategories = res.data.data;
          this.$stopLoader();
        });
    },
    getFarminCategories() {
      this.$startLoader();
      this.$store
        .dispatch("GET_FARMINGSECTORS", { token: this.$getToken() })
        .then((res) => {
          this.farmingCategories = res.data.data;
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getProvinces();
    this.getProductCategories();
    this.getFarminCategories();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";

form {
  width: 90%;
  border-radius: 5px;
  position: relative;
  margin: 2rem 2rem;
}

.submit {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  background: $blue;
  color: #fff;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  &:hover {
    background: darken($blue, 10%);
  }
}
</style>
  
<template>
  <notifications position="bottom right" style="z-index: 99999999999" />
  <iloader v-if="$store.state.isLoading" />
  <router-view />
</template>
<script>
import iloader from "./views/components/iloader.vue";
export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    iloader,
  },
  methods: {
    getModules() {
      this.$store.dispatch("GET_ALL_MODULES").then((res) => {
        this.$store.state.ismodules = res.data.data.data;
      });
    },
  },
  mounted() {
    this.$getCountries();
    this.getModules();
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import "@/assets/scss/main.scss";
* {
  font-family: "Fira Sans", sans-serif;
  font-family: "Poppins", sans-serif;
  // font-family: "Roboto Condensed", sans-serif;
}
.form-body {
  .form-group {
    margin-bottom: 20px;
    position: relative;
    label {
      display: block;
      margin-bottom: 5px;
      position: absolute;
      top: 5px;
      left: 10px;
      font-size: 13px;
      color: #5e5e5e;
    }
    input,
    select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;
    }
    .submit,
    .prev {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;
      background: $blue;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        background: darken($blue, 10%);
      }
    }
    .prev {
      background: #000;
      &:hover {
        background: #000;
      }
    }
  }
}
.page-content {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.search {
  input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
  }
}

.tabbing {
  display: flex;
  column-gap: 20px;
  margin-top: 2rem;
  margin-left: 1rem;
  button {
    outline: none;
    margin: 0 0 15px 0;
    display: flex;
    align-items: center;
    padding: 10px 25px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1rem;
    column-gap: 15px;
    border: 1px solid $blue;
    justify-content: center;
    white-space: nowrap;
    background: #fff;
    color: $blue;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      background: inherit;
      display: none;
    }
    &.active {
      color: #fff;
      background: $blue;
      &::before {
        display: flex;
      }
    }
  }
}
.form-group {
  input,
  select,
  textarea {
    margin-top: 0;
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
  }
  label {
    font-size: 0.8rem;
    color: #2f2f2f;
    margin-bottom: 0;
    span {
      font-size: 0.6rem;
      color: #821010;
    }
  }
  .input-group {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 1rem;
    width: 100%;
  }
  &.group-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  &.group-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
  }
  &.group-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
  }
}
tr {
  border-bottom: 1px solid #ddd;
  &:hover {
    background: #f0f0f0;
  }
  td {
    padding: 10px;
    img {
      &.item {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  button {
    margin: 3px;
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
  .verify {
    padding: 0.15rem 0.8rem;
    border-radius: 5px;
    background: #4da24d;
    color: #fff;
    display: inline-block;
  }
  .unverify {
    padding: 0.15rem 0.8rem;
    border-radius: 5px;
    background: #2e2e2e;
    color: #fff;
    display: inline-block;
  }
  .normal {
    padding: 0.15rem 0.8rem;
    border-radius: 5px;
    background: $blue;
    color: #fff;
    display: inline-block;
  }
  .delete {
    padding: 0.15rem 0.8rem;
    border-radius: 5px;
    background: #a44d4d;
    color: #fff;
    display: inline-block;
  }
}
.submit {
  padding: 0.5rem 2rem;
  border-radius: 5px;
  background: $blue !important;
  color: #fff;
  display: inline-block;
}
</style>
<template>
  <div class="card p-4 border rounded shadow-lg mb-4">
    <h2 class="text-xl font-bold mb-2">{{ data.cooperative_names }}</h2>
    <p>
      <span class="text-gray-700 font-bold">Location: </span
      >{{ `  ${data.district} - ${data.sector} ` }}
    </p>
    <p>
      <span class="font-bold text-gray-700">Main Product: </span
      >{{ data.mainProduct.name }}
    </p>
    <p v-if="data.President">
      <span class="font-bold text-gray-700">President: </span
      >{{ data.President }}
    </p>
    <p v-else>
      <span class="font-bold text-gray-700">Owner: </span
      >{{ data.addedBy.names }}
    </p>
    <div class="flex justify-end mt-4">
      <button class="text-white px-4 py-2 rounded mr-2" @click="requestToJoin()" v-if="notMember">Request Join</button>
      <router-link
        :to="{ name: 'CooperativeProfile', params: { id: data.id } }"
        class="a"
      >
        <button class="text-white px-4 py-2 rounded">View cooperative</button>
      </router-link>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    data: Object,
    notMember: Boolean,
  },
  data() {
    return {
      member: true,
    };
  },
  methods: {
    requestToJoin() {
      this.$emit("requestToJoin", this.data);
    },
  },
  mounted() {
    if (this.data.President) {
      this.member = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

button {
  background-color: $blue;
}
.card {
  height: fit-content;
}
</style>


  
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// plugs
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/css/tailwind.css';
import VueMultiselect from 'vue-multiselect'
import Notifications from '@kyvg/vue3-notification'
// mixin
import CanvasJSChart from '@canvasjs/vue-charts';
import Mixin from './mixins/mixin.vue'
import VueApexCharts from "vue3-apexcharts";
// components
import SiteNavbar from './views/components/SiteNavbar.vue'
import SiteFooter from './views/components/SiteFooter.vue'
import ChangeLang from './views/components/ChangeLang.vue'
import loggedUser from './views/components/loggedUser.vue'
import Model from './views/components/Model.vue'
import confirmModel from './views/components/confirmModel.vue'
import dashboardTopbar from './views/dashboard/components/Topbar.vue'
import OUpload from './components/OUpload.vue'
import viewProduction from './views/production/viewProduction.vue'
import Pagination from './components/Pagination.vue'
import ViewOrder from './views/orders/components/ViewOrder.vue'

const vue = createApp(App)
vue.component('site-navbar', SiteNavbar)
vue.component('site-footer', SiteFooter)
vue.component('change-lang', ChangeLang)
vue.component('logged-user', loggedUser)
vue.component('VueMultiselect', VueMultiselect)
vue.component('model', Model)
vue.component('confirm-model', confirmModel)
vue.component('dashboard-topbar', dashboardTopbar)
vue.component('o-upload', OUpload)
vue.component('production-view',viewProduction)
vue.component('pagination',Pagination)
vue.component('view-order',ViewOrder)

vue.use(Notifications)
vue.use(CanvasJSChart)
vue.use(VueApexCharts)

vue.use(VueAxios, axios)
vue.mixin(Mixin)
vue.use(store)
vue.use(router)
vue.mount('#app')
import { createRouter, createWebHistory } from 'vue-router'
import Register from '../views/site/Register.vue'
import Login from '../views/site/Login.vue'
import EmailVerification from '../views/site/EmailVerification.vue'
import VerifyEmail from '../views/site/VerifyEmail.vue'
import UserProfile from '../views/site/users/UserProfile.vue'
import EditProfile from '../views/site/users/EditProfile.vue'

import ForgotPassword from '../views/site/Forgot.vue'
import ChangePassword from '../views/site/ChangePassword.vue'
import ResetPassword from '../views/site/ResetPassword.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Home from '../views/dashboard/Home.vue'
//farms
import addFarm from '../views/farms/addFarm'
import viewFarm from '../views/farms/viewFarm.vue'

// cooperatives
import addCooperative from '../views/cooperatives/addCooperative.vue'
import viewCooperatives from '../views/cooperatives/viewCooperatives.vue'
import CooperativeProfile from '../views/cooperatives/CooperativeProfile.vue'
import cooperativeMembers from '../views/cooperatives/cooperativeMembers.vue'
import ViewAllCooperative from '../views/cooperatives/ViewAllCooperative.vue'

//production
import AddProduction from '../views/production/AddProduction.vue'
import viewProduction from '../views/production/viewProduction.vue'
import AddMyProduction from '../views/production/AddMyProduction.vue'

//orders
import ProductsToOrder from '../views/orders/ProductsToOrder.vue'
import MyOrders from '../views/orders/MyOrders.vue'
import AllOrders from '../views/orders/AllOrders.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/user/email-verification',
    name: 'EmailVerification',
    component: EmailVerification
  },

  {
    path: '/user/verifyAccount/:token',
    name: 'VerifyEmail',
    component: VerifyEmail
  },

  {
    path: '/user/profile',
    name: "UserProfile",
    component: UserProfile
  },
  {
    path: '/farms/add-new',
    name: 'AddFarm',
    component: addFarm
  },

  {
    path: '/user/edit-profile',
    name: "EditProfile",
    component: EditProfile
  },

  {
    path: '/user/resetPassword/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },

  {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
      },

      {
        path: '/farms',
        name: 'ViewFarms',
        component: viewFarm
      },
      {
        path: '/add-farm',
        name: 'AddFarm',
        component: addFarm
      },
      {
        path: '/cooperatives/add-new',
        name: "addCooperative",
        component: addCooperative
      },
      {
        path: '/cooperatives',
        name: "viewCooperatives",
        component: viewCooperatives
      },
      {
        path: '/cooperatives/profile',
        name: "CooperativeProfile",
        component: CooperativeProfile
      },
      {
        path: '/cooperatives/all',
        name: "ViewAllCooperatives",
        component: ViewAllCooperative
      },
      {
        path: '/add-production/:id',
        name: "AddProduction",
        component: AddProduction
      },
      {
        path: '/view-productions',
        name: "viewProduction",
        component: viewProduction
      },
      {
        path: '/members/:id',
        name: "CooperativeMembers",
        component: cooperativeMembers
      },

      {
        path:'/order-products',
        name:"ProductsToOrder",
        component:ProductsToOrder
      },
      {
        path:'/my-orders',
        name:"MyOrders",
        component:AllOrders
      },

      {
        path: '/cooperatives/profile/:id',
        name: "CooperativeProfile",
        component: CooperativeProfile
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      },
      {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword
      },
      {
        path: '/add-my-production',
        name: "AddMyProduction",
        component: AddMyProduction
      }
    ]
  },


  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

<template>  
    <div id="">
      <h1 class="text-lg ">Complete Profile</h1>
  
      <select v-model="accountCategory" class="input-small">
        <option value="individual">Individual</option>
        <option value="business">Business</option>
      </select>
  <br/>
    </div>
    <button type="submit" class="btn">Save</button>

  </template>
  
  <script>
  export default {
    name: 'CompleteProfile',
    data() {
      return {
        accountCategory: '',
      };
    },
    mounted() {
     this.$getUser(); 
    },
    methods: {
      save() {
      },
    },
  };
  </script>
  
  <style lang="scss">
    @import "@/assets/scss/main.scss";
  .input-small {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left:40px;
  }
  
  .btn {
    background-color: $blue !important;
    color:white;
    border-radius: 4px;
    padding: 10px 20px;
    width:200px;
    margin:40px;
    cursor: pointer;
  }
  

  </style>
  
<template>
  <div class="page-content">
    <dashboard-topbar
      :pageTitle="'Add products to your production'"
    />

    <form @submit.prevent="addProduction" class="">
      <div class="form-group group-2">
        <div class="input-group" v-if="user">
          <label for="">Owner</label>
          <input
            type="text"
            :value="user.names+' - (You)'"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Owner"
            disabled
          />
        </div>
        <div class="input-group">
          <label for="">Product</label>
          <select name="" id="" v-model="newItem.product_category">
            <option value="">Select Product</option>
            <option
              v-for="product in productCategories"
              :key="product.id"
              :value="product.id"
            >
              {{ product.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group group-3">
        <div class="input-group">
          <label for="">Quantity</label>
          <input
            type="text"
            v-model="newItem.production_quantity"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Production"
          />
        </div>
        <div class="input-group">
          <label for="">Product Unit</label>
          <!-- kg, littres, Tons, boxess, dozens, crates, pieces, grams -->
          <select name="" id="" v-model="newItem.units">
            <option value="">Select Unit</option>
            <option value="Kg">Kg</option>
            <option value="Littres">Littres</option>
            <option value="Tons">Tons</option>
            <option value="Boxes">Boxes</option>
            <option value="Dozens">Dozens</option>
            <option value="Crates">Crates</option>
            <option value="Pieces">Pieces</option>
            <option value="Grams">Grams</option>
          </select>
        </div>
        <div class="input-group">
          <label for="">Unit Price</label>
          <input
            type="text"
            v-model="newItem.product_price"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Price"
          />
        </div>
      </div>
      <div class="form-group group-2">
        <div class="input-group">
          <label for="">Description</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="4"
            v-model="newItem.product_description"
            placeholder="Description"
          ></textarea>
        </div>
        <div class="input-group">
          <label for="">Image</label>
          <o-upload
            :multiple="false"
            :uploadUrl="'files/upload'"
            @imagesUploaded="
              (data) => {
                newItem.image = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="newItem.image"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
        </div>
      </div>

      <button type="submit" class="submit">Save</button>
    </form>
  </div>
</template>
  
  <script>
export default {
  name: "AddMyProduction",
  data() {
    return {
      newItem: {
        product_category: "",
        cooperative_id: "null",
        production_quantity: 0,
        product_price: 0,
        product_description: "",
        image: "/assets/images/maize.jpg",
        units: "Kg",
      },
      productCategories: [],
      user: null,
    };
  },

  methods: {
    addProduction() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_PRODUCT", {
          token: this.$getToken(),
          data: this.newItem,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
            this.$router.push({ name: "viewProduction" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.message,
          });
        });
    },

    getProductCategories() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PRODUCTSCATEGORIES", { token: this.$getToken() })
        .then((res) => {
          this.productCategories = res.data.data;
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getProductCategories();
    this.newItem.cooperative_id = this.$route.params.id;
    this.user = this.$getUser();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";

form {
  width: 90%;
  border-radius: 5px;
  position: relative;
  margin: 2rem 2rem;
}

.submit {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  background: $blue;
  color: #fff;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  &:hover {
    background: darken($blue, 10%);
  }
}
</style>
  
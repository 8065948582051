<template>
  <div class="Home">
    <div class="left" :class="$store.state.showSANav ? 'active' : ''">
      <Sidebar />
    </div>
    <div class="right" :class="!$store.state.showSANav ? 'active' : ''">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>
  
  <script>
import Sidebar from "@/views/dashboard/components/Sidebar.vue";
export default {
  name: "Home",
  components: {
    Sidebar,
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  display: flex;
  flex-direction: row;
  height: 100vh;
  max-height: 100vh;
  .left {
    width: 0px;
    transition: all 0.3s ease-in-out;
    position: relative;
    &.active {
      width: 260px;
    }
  }
  .right {
    width: calc(100vw - 260px);
    &.active {
      width: 100vw;
    }
    .content {
      overflow-y: auto;
      height: calc(100vh);
    }
  }
}
</style>
<template>
    <dashboard-topbar/>

    <div class="container mx-auto p-4">

      <h2 class="text-xl font-semibold mb-4">Your farms</h2>
      <div class="flex justify-between mb-4">

        <router-link :to="{name:'AddFarm'}" class="a">
          <button class="add-btn">Add New Farm</button>

        </router-link>

            
      </div>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class=" text-white">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Owner names
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                number of animals/fields
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>

          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(item, index) in tableData" :key="index" :class="{ 'bg-purple-100': index % 2 === 0 }">
              <td class="px-6 py-4 whitespace-nowrap">{{ item.name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.owner }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.count }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.description }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.location }}</td>

              <td class="flex items-center space-x-2 mart">
                <a href="#"><img src="/assets/images/delete.svg" alt="" /></a>
                <a href="#"><img src="/assets/images/edit.svg" alt="" /></a>
                <a href="#"><img src="/assets/images/suppliers-2.svg" alt="" /></a>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
  </template>
  
  <script>


  export default {
    data() {
        return {
            tableData: [
                {
                    name: "Category 1",
                    owner: "Item 1",
                    count: 100,
                    description: "Description 1",
                    location: "Kigali, Gasabo",
                },
                {
                    name: "Category 1",
                    owner: "Item 1",
                    count: 100,
                    description: "Description 1",
                    location: "Kigali, Gasabo",
                },
                {
                    name: "Category 1",
                    owner: "Item 1",
                    count: 100,
                    description: "Description 1",
                    location: "Kigali, Gasabo",
                },
            ],
        };
    },
    
    methods: {

  },
}
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/scss/main.scss";

            .plan{
            width: 120px;
            padding: 10px;
            margin-left: 500px;
            border: 1px solid $blue;
            border-radius: 5px;
            outline: none;
            background: white;
            color: $blue;
            font-weight: 600;
            }

            .mart{
                margin-top: 20px;
            }

            .add-btn{
            width: 160px;
            padding: 10px;
            border: 1px solid;
            border-radius: 5px;
            outline: none;
            background: $blue;
            color: white;
            font-weight: 600;
            }

  </style>
  
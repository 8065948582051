<template>
  <dashboard-topbar :pageTitle="'Your orders'" />
  <div class="All-orders">
    <!-- orders table -->
    <div class="table-header">
      <h2>All orders</h2>
      <input
        type="text"
        v-model="filter"
        placeholder="Search by status, produc, client or date"
        class="w-full px-4 py-4 rounded border border-gray-300 focus:border-blue-500"
        @keyup="filteredOrders"
      />
    </div>
    <div class="item-per-page">
      <label for="">Items per page: </label>
      <select name="" id="" v-model="itemsPerPage" @change="changePage(1)">
        <option>5</option>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
        <option>500</option>
      </select>
    </div>
    <table>
      <thead>
        <tr>
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Client</th>
          <th>Date</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.id">
          <td>{{ order.product.productCategory.name }}</td>
          <td>{{ order.quantity }}</td>
          <td>{{ order.product.price }} Rwf</td>
          <td>{{ order.client.name }}</td>
          <td>{{ order.date }}</td>
          <td>{{ order.status }}</td>
          <td>
            <button class="normal" @click="viewOrder(order)">
              <label for="">View</label>
            </button>
            <button class="delete" @click="cancelOrder(order)">
              <label for="">Cancel</label>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination">
      <pagination
        :totalItems="allOrders.length"
        :itemsPerPage="itemsPerPage"
        @pageChanged="
          (page) => {
            changePage(page);
          }
        "
      />
    </div>
    <confirm-model
      :modelTitle="'Cancel order'"
      :smallModel="true"
      v-if="cancelPopupOpen"
      @closeModel="cancelPopupOpen = false"
      @confirmModel="confirmCancel()"
      :displayText="
        'Are you sure you want to cancel ' +
        selectedItem.client.name +
        '\'s order?'
      "
    ></confirm-model>
    <view-order
      v-if="showViewOrderPopup"
      :order="selectedItem"
      @closePopup="showViewOrderPopup = false"
    />
  </div>
</template>
    
    <script>
export default {
  name: "ProductionView",
  data() {
    return {
      allOrders: [
        {
          id: 1,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 2,
          quantity: 100,
          date: "2020-10-10",
          status: "Delivered",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 3,
          quantity: 100,
          date: "2020-10-10",
          status: "Rejected",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 4,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 5,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 6,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 7,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 8,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 9,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 10,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
        {
          id: 11,
          quantity: 100,
          date: "2020-10-10",
          status: "Pending",
          client: {
            id: 1,
            name: "John Doe",
            email: "john@gmail.com",
            phone: "0781234567",
            Message: "I need 100 eggs",
            address: "Kigali",
          },
          product: {
            id: "adad5b6c-782f-44a8-8cbe-93a0861a769e",
            price: 2400,
            quantity: 40,
            description: "desc",
            image: "/assets/images/maize.jpg",
            sellingStatus: "Available",
            createdAt: "Tue Oct 03 2023",
            addedBy: {
              names: "testing",
              email: "alt.ru-1odw4cqv@yopmail.com",
              phone: "0732945237",
              roleName: "User",
              address: "0",
            },
            productCategory: {
              id: "ca454415-fe40-4768-9090-4aa283da5845",
              name: "Poultry meat",
            },
            owner: {
              type: "Cooperative",
            },
            cooperative: {
              id: "950f7624-f659-43fe-b009-9eb3d3af31d5",
              sector: "Busasamana",
              district: "NYANZA",
              province: "South Provice",
              cell: "Kavumu",
              email: "testking@test.to",
              phone: "0762945235",
              members: 5,
              productionSize: "70 kgs",
            },
          },
        },
      ],
      orders: [],
      deliveryPopupOpen: false,
      cancelPopupOpen: false,
      selectedItem: {},
      showViewOrderPopup: false,
      filter: "",
      itemsPerPage: 5,
      currentPage: 1,
    };
  },
  methods: {
    getAllOrders() {
      this.changePage(1);
      this.$startLoader()
      this.$store
        .dispatch("GET_ALL_ORDERS", { token: this.$getToken() })
        .then((res) => {
          this.allOrders = res.data.data;
          console.log(allOrders)
          this.$stopLoader();
        });
    },
    filteredOrders() {
      this.orders = this.allOrders.filter((order) => {
        return (
          order.client.name.toLowerCase().includes(this.filter.toLowerCase()) ||
          order.date.toLowerCase().includes(this.filter.toLowerCase()) ||
          order.status.toLowerCase().includes(this.filter.toLowerCase()) ||
          order.product.productCategory.name
            .toLowerCase()
            .includes(this.filter.toLowerCase())
        );
      });
    },

    changePage(page) {
      this.currentPage = page;
      this.orders = this.$changePage(this.allOrders, page, this.itemsPerPage);
    },
    viewOrder(order) {
      this.selectedItem = order;
      this.showViewOrderPopup = true;
    },
    cancelOrder(order) {
      this.selectedItem = order;
      this.cancelPopupOpen = true;
    },
    confirmCancel() {
      this.cancelPopupOpen = false;
      this.$notify({
        type: "success",
        title: "Order canceled",
        text: "Order canceled successfully",
      });
    },
  },
  mounted() {
    this.getAllOrders();
  },
};
</script>
    
    <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.All-orders {
  padding: 0 20px;
  padding-bottom: 5rem;
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
    input {
      width: 400px;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      text-align: left;
      padding: 10px 0;
      font-size: 16px;
      font-weight: 600;
      color: black;
    }
    td {
      padding: 10px 0;
      font-size: 16px;
      color: black;
      border-bottom: 1px solid #ccc;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.item-per-page {
  display: flex;
  select {
    width: 70px;
    border-radius: 4px;
    border: 1px solid $blue;
    padding: 0 10px;
    outline: none;
    margin-left: 10px;
  }
}
</style>
    
<template>
  <dashboard-topbar
    :pageTitle="cooperativeProfiles.cooperative_names + ' Profile'"
    v-if="cooperativeProfiles"
  />

  <div class="container mx-auto p-4" v-if="cooperativeProfiles">
    <cooperative-profile
      :key="index"
      :cooperative="cooperativeProfiles"
      :cooperativeName="cooperativeProfiles.cooperative_names"
      :president="cooperativeProfiles.president"
      :added="cooperativeProfiles.addedBy"
      :production="cooperativeProfiles.production_size"
      :location="cooperativeProfiles.sector"
      @requestJoin="requestJoin"
    />
    <div class="bottom-content" v-if="activePage == 'farms'">
      <div class="title">
        <h3>Farms</h3>
      </div>
      <div class="farms">
        <div
          class="farm"
          v-for="farm in cooperativeProfiles.farmsData"
          :key="farm.id"
        >
          <div class="name">
            <label for="">Name:</label>
            <p>{{ farm.name }}</p>
          </div>
          <div class="size">
            <label for="">Size:</label>
            <p>{{ farm.size }} m<sup>2</sup></p>
          </div>
          <div class="district">
            <label for="">Location:</label>
            <p>{{ farm.district }}, {{ farm.sector }}, {{ farm.cell }}</p>
          </div>
          <div class="type">
            <label for="">Type:</label>
            <p>{{ farm.type }}</p>
          </div>
          <div class="productivity">
            <label for="">Productivity:</label>
            <p>{{ farm.productivity }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="posts" v-if="activePage == 'products'">
      <div class="title">
        <h3>Production</h3>
      </div>
      <div>
        <confirm-model
          :modelTitle="'Delete Production'"
          :smallModel="true"
          v-if="deletePopupOpen"
          @closeModel="deletePopupOpen = false"
          @confirmModel="confirmDelete()"
          :displayText="'Are you sure you want to delete this record?'"
        ></confirm-model>
        <model
          :modelTitle="'Edit Production'"
          :largeModel="true"
          v-if="showEditPopup"
          @closeModel="showEditPopup = false"
        >
          <form @submit.prevent="confirmUpdate()" class="">
            <div class="form-group group-3">
              <div class="input-group">
                <label for="">Product</label>
                <select name="" id="" v-model="selectedItem.productCategory.id">
                  <option value="">Select Product</option>
                  <option
                    v-for="product in productCategories"
                    :key="product.id"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </option>
                </select>
              </div>
              <div class="input-group">
                <label for="">Quantity</label>
                <input
                  type="text"
                  v-model="selectedItem.quantity"
                  class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Production"
                />
              </div>
              <div class="input-group">
                <label for="">Unit Price</label>
                <input
                  type="text"
                  v-model="selectedItem.price"
                  class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Price"
                />
              </div>
            </div>
            <div class="form-group group-2">
              <div class="input-group">
                <label for="">Description</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="4"
                  v-model="selectedItem.description"
                  placeholder="Description"
                ></textarea>
              </div>
              <div class="input-group">
                <label for="">Image</label>
                <o-upload
                  :multiple="false"
                  :uploadUrl="'files/upload'"
                  @imagesUploaded="
                    (data) => {
                      selectedItem.image = data;
                    }
                  "
                  @imagesUploadedFailed="
                    (data) => {
                      console.log('failed');
                    }
                  "
                  :value="selectedItem.image"
                  @startLoader="() => $startLoader()"
                  @stopLoader="() => $stopLoader()"
                />
              </div>
            </div>

            <button type="submit" class="submit">Save</button>
          </form>
        </model>
        <table
          class="table-auto w-full min-w-full bg-white mt-3"
          v-if="allProducts.length > 0"
        >
          <thead class="text-white">
            <tr>
              <th width="80">#</th>
              <th>Category</th>
              <th>Unit Price</th>
              <th>Total units</th>
              <th>Added on</th>
              <th>Added by</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="production in products"
              :key="production.id"
              :class="{ 'bg-blue-100': production.id % 2 === 0 }"
            >
              <td width="80">
                <img
                  :src="production.image"
                  alt=""
                  @error="$imageurlalt()"
                  class="item"
                />
              </td>
              <td class="px-4 py-2">{{ production.productCategory.name }}</td>
              <td class="px-4 py-2">{{ production.price }} Rwf</td>
              <td class="px-4 py-2">{{ production.quantity }}</td>
              <td class="px-4 py-2">{{ production.createdAt }}</td>
              <td class="px-4 py-2">{{ production.addedBy.names }}</td>
              <td style="width: 200px">
                <button class="normal" @click="startEdit(production)">
                  <label for="">Edit</label>
                </button>
                <button class="delete" @click="startDelete(production)">
                  <label for="">Delete</label>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <confirm-model
          :modelTitle="'Delete Production'"
          :smallModel="true"
          v-if="deletePopupOpen"
          @closeModel="deletePopupOpen = false"
          @confirmModel="confirmDelete()"
          :displayText="'Are you sure you want to delete this record?'"
        ></confirm-model>
        <model
          :modelTitle="'Edit Production'"
          :largeModel="true"
          v-if="showEditPopup"
          @closeModel="showEditPopup = false"
        >
          <form @submit.prevent="confirmUpdate()" class="">
            <div class="form-group group-4">
              <div class="input-group">
                <label for="">Product</label>
                <select name="" id="" v-model="selectedItem.productCategory.id">
                  <option value="">Select Product</option>
                  <option
                    v-for="product in productCategories"
                    :key="product.id"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </option>
                </select>
              </div>
              <div class="input-group">
                <label for="">Quantity</label>
                <input
                  type="text"
                  v-model="selectedItem.quantity"
                  class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Production"
                />
              </div>
              <div class="input-group">
                <label for="">Product Unit</label>
                <!-- kg, littres, Tons, boxess, dozens, crates, pieces, grams -->
                <select name="" id="" v-model="selectedItem.units">
                  <option value="">Select Unit</option>
                  <option value="Kg">Kg</option>
                  <option value="Littres">Littres</option>
                  <option value="Tons">Tons</option>
                  <option value="Boxes">Boxes</option>
                  <option value="Dozens">Dozens</option>
                  <option value="Crates">Crates</option>
                  <option value="Pieces">Pieces</option>
                  <option value="Grams">Grams</option>
                </select>
              </div>
              <div class="input-group">
                <label for="">Unit Price</label>
                <input
                  type="text"
                  v-model="selectedItem.price"
                  class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Price"
                />
              </div>
            </div>
            <div class="form-group group-2">
              <div class="input-group">
                <label for="">Description</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="4"
                  v-model="selectedItem.description"
                  placeholder="Description"
                ></textarea>
              </div>
              <div class="input-group">
                <label for="">Image</label>
                <o-upload
                  :multiple="false"
                  :uploadUrl="'files/upload'"
                  @imagesUploaded="
                    (data) => {
                      selectedItem.image = data;
                    }
                  "
                  @imagesUploadedFailed="
                    (data) => {
                      console.log('failed');
                    }
                  "
                  :value="selectedItem.image"
                  @startLoader="() => $startLoader()"
                  @stopLoader="() => $stopLoader()"
                />
              </div>
            </div>

            <button type="submit" class="submit">Save</button>
          </form>
        </model>
      </div>
    </div>
  </div>
</template>
  
  <script>
import CooperativeProfile from "../components/Profile.vue";

export default {
  components: {
    CooperativeProfile,
  },
  data() {
    return {
      cooperativeProfiles: null,
      activePage: "products",
      products: [],

      allProducts: [],

      deletePopupOpen: false,
      selectedItem: {},
      showEditPopup: false,
      productCategories: [],
      filter: "",
    };
  },
  methods: {
    requestJoin() {
      alert("Request to join sent!");
    },

    getCooperative() {
      this.$startLoader();
      this.$store
        .dispatch("GET_COOPERATIVE_BY_ID", {
          token: this.$getToken(),
          data: { cooperative_id: this.$route.params.id },
        })
        .then((res) => {
          this.cooperativeProfiles = res.data.data;
          this.getCooperativeProducts(this.cooperativeProfiles.id);
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          }
        });
    },
    getCooperativeProducts(id) {
      this.$startLoader();
      this.$store
        .dispatch("GET_COOPERATIVE_PRODUCTS", {
          token: this.$getToken(),
          data: { cooperative_id: id },
        })
        .then((res) => {
          this.products = res.data.data;
          this.allProducts = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          }
        });
    },
    startEdit(production) {
      this.selectedItem = { ...production };
      if (this.selectedItem.quantity.split(" ")[1] == "null") {
        this.selectedItem.units = "Kg";
        this.selectedItem.quantity = this.selectedItem.quantity.split(" ")[0];
      } else {
        this.selectedItem.units = this.selectedItem.quantity.split(" ")[1];
        this.selectedItem.quantity = this.selectedItem.quantity.split(" ")[0];
      }
      this.showEditPopup = true;
    },
    startDelete(production) {
      this.selectedItem = production;
      this.deletePopupOpen = true;
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_PRODUCT", {
          token: this.$getToken(),
          id: this.selectedItem.id,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Production deleted successfully",
            });
            this.deletePopupOpen = false;
            this.getCooperativeProducts(this.cooperativeProfiles.id);
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: "Failed to delete production",
            });
          }
        });
    },
    confirmUpdate() {
      let updateItem = {
        product_category: this.selectedItem.productCategory.id,
        product_description: this.selectedItem.description,
        production_quantity: this.selectedItem.quantity,
        product_price: this.selectedItem.price,
        image: this.selectedItem.image,
        units: this.selectedItem.units,
      };
      this.$startLoader();
      this.$store
        .dispatch("EDIT_PRODUCT", {
          token: this.$getToken(),
          product_id: this.selectedItem.id,
          data: updateItem,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Production updated successfully",
            });
            this.showEditPopup = false;
            this.getCooperativeProducts(this.cooperativeProfiles.id);
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: "Failed to update production",
            });
          }
        });
    },
    getProductCategories() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PRODUCTSCATEGORIES", { token: this.$getToken() })
        .then((res) => {
          this.productCategories = res.data.data;
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getCooperative();
    this.getProductCategories();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
form {
  width: 100% !important;
}
.button {
  border: 1px solid purple;
  padding: 12px;
  border-radius: 5px;
}
.bottom-content {
  margin-top: 2rem;
  padding: 1rem 2rem;
  .title {
    margin-bottom: 1rem;
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .farms {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    .farm {
      padding: 1rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      .name,
      .size,
      .district,
      .type,
      .productivity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
        p {
          font-size: 1rem;
          font-weight: 600;
        }
        label {
          font-size: 0.9rem;
        }
      }
    }
  }
}
.posts {
  margin-top: 2rem;
  padding: 1rem 2rem;
  .title {
    margin-bottom: 1rem;
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .posts-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;

    .post {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #eee;

        .img {
          width: 100px;
          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 5px 0 0 5px;
            overflow: hidden;
          }
        }
        .right {
          width: calc(100% - 120px);
          padding-left: 10px;
          .header-title {
            margin-bottom: 1rem;
            h4 {
              font-size: 1.25rem;
              font-weight: 600;
            }
          }
          .header-content {
            p {
              font-size: 0.9rem;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        .date,
        .countNumber,
        .price {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0.5rem;
          width: 100%;
          p {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}
th {
  background: $blue;
  text-align: left;
}
</style>
<template>
  <div class="Sidebar">
    <div class="sidebar-header">
      <img src="/assets/images/infomaster.png" alt="" />
      <label for="Infomaster">{{ $txt("Agriculture") }}</label>
      <div class="lines">
        <div class="nav-lines" @click="$toggleSANav()">
          <i class="fa fa-angle-left" v-if="$store.state.showSANav"></i>
          <i class="fa fa-angle-right" v-else></i>
        </div>
      </div>
    </div>
    <div class="sidebar-links">
      <router-link
        :to="{ name: 'Dashboard' }"
        class="sidebar-link"
        :class="urlContains('/agriculture/dashboard') ? 'active' : 'inactive'"
      >
        <label for="Module name">Dashboard</label>
      </router-link>
      <router-link
        :to="{ name: 'ViewFarms' }"
        class="sidebar-link multilink"
        id="IEDU"
        :class="urlContains('/agriculture/farms') ? 'active' : 'inactive'"
      >
        <label for="Module name">Farms</label>
      </router-link>

      <div
        class="sidebar-link multilink"
        id="IRAB"
        :class="$urlContains('/cooperatives') ? 'active' : ''"
      >
        <label for="Module name" @click="toggleSubLinks('Cooperatives')"
          >Cooperatives <i class="fa fa-angle-down"></i
        ></label>
        <div
          class="sub-links"
          :class="this.activePage == 'Cooperatives' ? 'active' : ''"
        >
          <router-link :to="{ name: 'viewCooperatives' }">
            <label for="">My Cooperatives</label>
          </router-link>
          <router-link :to="{ name: 'ViewAllCooperatives' }">
            <label for="">All Cooperatives</label>
          </router-link>
        </div>
      </div>

      <div
        class="sidebar-link multilink"
        id="IRAB"
        :class="$urlContains('/production') ? 'active' : ''"
      >
        <label for="Module name" @click="toggleSubLinks('Production')"
          >Production <i class="fa fa-angle-down"></i
        ></label>
        <div
          class="sub-links"
          :class="this.activePage == 'Production' ? 'active' : ''"
        >
          <router-link :to="{ name: 'AddMyProduction'}">
            <label for="">Add a Product</label>
          </router-link>
          <router-link :to="{ name: 'viewProduction' }">
            <label for="">My Productions</label>
          </router-link>
        </div>
      </div>
      <div
        class="sidebar-link multilink"
        id="IRAB"
        :class="$urlContains('/orders') ? 'active' : ''"
      >
        <label for="Module name" @click="toggleSubLinks('Orders')"
          >Orders <i class="fa fa-angle-down"></i
        ></label>
        <div
          class="sub-links"
          :class="this.activePage == 'Orders' ? 'active' : ''"
        >
          <!-- <router-link :to="{name:'ProductsToOrder'}">
            <label for="">Products to Order</label>
          </router-link> -->
          <router-link :to="{name:'MyOrders'}">
            <label for="">Your Orders</label>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      activePage: "",
      userRole: "",
    };
  },
  methods: {
    toggleSubLinks(id) {
      if (this.activePage == id) {
        this.activePage = "";
      } else {
        this.activePage = id;
      }
    },
    redirectTo(id) {
      if (id == "Dashboard") {
        this.$router.push({ name: "SuperAdminDashboard" });
      }
      if (id == "Users") {
        this.$router.push({ name: "SuperAdminUsers" });
      }
    },
    urlContains(id) {
      return this.$route.path.includes(id);
    },
  },
  mounted() {
    if (this.$getUser()) {
      this.userRole = this.$getUser();
    } else {
      this.$notify({
        title: "Error",
        type: "error",
        text: "You are not authorized to access this page",
      });
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Sidebar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  border-right: 1px solid #fff;
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.1);
  .sidebar-header {
    background: #fff;
    padding: 17px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 35px;
      margin-left: 10px;
    }
    label {
      font-size: 1.15rem;
      font-weight: 600;
      color: #000;
      text-align: center;
      display: block;
      margin: 0 10px;
    }
    .lines {
      position: absolute;
      right: -2.8rem;
      background: #fff;
      padding: 5px;
      border-radius: 0px 5px 5px 0px;
      box-shadow: 5px 0 10px 2px rgba(0, 0, 0, 0.1);
      .nav-lines {
        display: flex;
        flex-direction: column;
        // border: 1px #000 solid;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border-radius: 50%;
        row-gap: 2px;
        cursor: pointer;
        width: 35px;
        height: 35px;
        background: $blue;
        color: #fff;
        &:hover {
          background-color: darken($color: $blue, $amount: 10);
        }
        div {
          width: 20px;
          border-top: 2px #fff solid;
          margin: 1px 0;
          &:nth-child(1) {
            transform: rotate(45deg);
            position: absolute;
          }
          &:nth-child(2) {
            transform: rotate(-45deg);
            position: absolute;
          }
        }
      }
    }
  }
  .sidebar-links {
    .sidebar-link {
      display: block;
      &:hover {
        background: #eee;
        cursor: pointer;
      }
      &.active {
        background: #eee;
        cursor: pointer;
      }
      label {
        padding: 10px;
        font-size: 1rem;
        font-weight: 600;
        color: #000;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .sub-links {
        padding: 10px;
        display: flex;
        flex-direction: column;
        height: 0;
        overflow: hidden;
        padding: 0;
        transition: all 0.3s ease-in-out;
        &.active {
          height: fit-content;
          padding: 10px;
        }
        .router-link-exact-active {
          background: #ccc;
        }
        a {
          display: block;
          margin: 10px;
          border-radius: 3px;
          border-left: 1px solid #ccc;
          &:hover {
            background: #ccc;
          }
          label {
            font-size: 1rem;
            font-weight: 400;
            padding: 10px;
          }
        }
      }
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    $txt(txt) {
      const lang = localStorage.getItem("lang");
      if (!lang) {
        localStorage.setItem("lang", "en");
        const lang = "en";
      }
      if (lang === "en") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.en == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.en;
        }
      }
      if (lang === "kny") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.en == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.kny;
        }
      }
    },
    $changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    $getCountries() {
      this.$store.dispatch("GET_COUNTRIES").then((res) => {
        this.$store.state.countries = res.data.data.data;
      });
    },
    $userLogin(user, token) {
      localStorage.setItem("i_user", JSON.stringify(user));
      localStorage.setItem("i_token", token);
      this.$router.push({ name: "Dashboard" });
    },
    $userLogout() {
      let userToken = this.$getToken();
      localStorage.removeItem("i_user");
      localStorage.removeItem("i_token");
      this.$store
        .dispatch("LOGOUT_USER", { token: userToken })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: res.data.error,
            });
          }
        })
        .catch((err) => {
          this.$router.push({ name: "Login" });
        });
    },
    $getUser() {
      let user = JSON.parse(localStorage.getItem("i_user"));
      if (user) {
        return user;
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    $getToken() {
      return localStorage.getItem("i_token");
    },
    $isSuperAdmin() {
      let user = this.$getUser();
      if (user.userRole.roleName == "Super Administrator") {
        return true;
      } else {
        return false;
      }
    },
    $isAdmin() {
      let user = this.$getUser();
      if (user.userRole.roleName == "Administrator") {
        return true;
      } else {
        return false;
      }
    },
    onCancel() {
      let loader = useLoading();
      loader.hide();
    },
    $startLoader() {
      this.$store.state.isLoading = true;
    },
    $stopLoader() {
      this.$store.state.isLoading = false;
    },
    $urlCompatible(url) {
      if (!url) {
        return;
      }
      return url.replace(/\s/g, "-");
    },
    $toggleSANav() {
      this.$store.state.showSANav = !this.$store.state.showSANav;
      localStorage.setItem("showSANav", this.$store.state.showSANav);
    },
    $urlContains(id) {
      return this.$route.path.includes(id);
    },
    $comma(amount) {
      if (!amount) {
        return;
      }
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    $dateToDay(date) {
      if (!date) {
        return;
      }
      // convert date like this 2021-10-12  to this format Saturday, 07,01,2023
      let newDate = new Date(date);
      let day = newDate.getDay();
      let month = newDate.getMonth();
      let year = newDate.getFullYear();
      let dayName = newDate.toLocaleString("default", { weekday: "long" });
      let monthName = newDate.toLocaleString("default", { month: "long" });
      return `${day} ${monthName}, ${year}`;
    },
    $imageurlalt() {
      event.target.src = "https://via.placeholder.com/300";
    },
    $changePage(allItems, page, itemsPerPage) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return allItems.slice(startIndex, endIndex);
    },
  },
  mounted() {
    let showSANav = localStorage.getItem("showSANav");
    if (showSANav != undefined && showSANav != null) {
      // convert to boolean
      if (showSANav == "true") {
        this.$store.state.showSANav = true;
      } else {
        this.$store.state.showSANav = false;
      }
    }
  },
};
</script>
import { createStore } from 'vuex'
import login from './modules/login'
import countries from './modules/countries'
import modules from './modules/modules'
import profile from './modules/profile'
import locations from './modules/locations'
import productCategories from './modules/productCategories'
import cooperatives from './modules/cooperatives'
import files from './modules/files'
import products from './modules/products'
import orders from './modules/orders'
export default createStore({
  state: {
    SiteName: "Infomaster",
    translations: [
      {
        id: 1,
        en: "Home",
        kny: "Ahabanza",
      },
      {
        id: 2,
        en: "Education",
        kny: "Uburezi",
      },
      {
        id: 3,
        en: "Login",
        kny: "Injira",
      },
      {
        id: 4,
        en: "Register",
        kny: "Iyandikishe",
      },
      {
        id: 5,
        en: "Domestic workers",
        kny: "Abakozi",
      },
      {
        id: 6,
        en: "Poultry",
        kny: "Ubworozi",
      },
      {
        id: 7,
        en: "Trucks",
        kny: "Amakamyo",
      },
      {
        id: 8,
        en: "Technicians",
        kny: "Abatekinisiye",
      },
      {
        id: 9,
        en: "Info",
        kny: "Amakuru",
      },
      {
        id: 10,
        en: "Add a school",
        kny: "Andikisha ishuri",
      },
      {
        id: 11,
        en: "School Name",
        kny: "Izina ry'ishuri",
      },
      {
        id: 12,
        en: "Category",
        kny: "Icyiciro",
      },
      {
        id: 13,
        en: "Email",
        kny: "Imeyili",
      },
      {
        id: 14,
        en: "Telephone",
        kny: "Telefoni",
      },
      {
        id: 15,
        en: "Website",
        kny: "Urubuga",
      },
      {
        id: 16,
        en: "Province",
        kny: "Intara",
      },
      {
        id: 17,
        en: "District",
        kny: "Akarere",
      },
      {
        id: 18,
        en: "Sector",
        kny: "Umurenge",
      },
      {
        id: 19,
        en: "Cell",
        kny: "Akagari",
      },
      {
        id: 20,
        en: "Next",
        kny: "Komeza",
      },
      {
        id: 21,
        en: "Level",
        kny: "Icyiciro",
      },
      {
        id: 22,
        en: "Previous",
        kny: "Gusubira inyuma",
      },
      {
        id: 23,
        en: "Submit",
        kny: "Emeza",
      },
      {
        id: 24,
        en: "Don't have an account?",
        kny: "Nta konti ufite?",
      },
      {
        id: 25,
        en: "Already have an account?",
        kny: "Ufite konti?",
      },
      {
        id: 26,
        en: "Log into",
        kny: "Injira muri",
      },
      {
        id: 27,
        en: "Create",
        kny: "Kwandika",
      },
      {
        id: 28,
        en: "Account",
        kny: "Konti",
      },
      {
        id: 29,
        en: "Username/email",
        kny: "izina ukoresha/imeyiri",
      },
      {
        id: 30,
        en: "Password",
        kny: "Ijambo ry'ibanga",
      },
      {
        id: 31,
        en: "Fullname",
        kny: "Izina ry'umuntu",
      },
      {
        id: 32,
        en: "Username",
        kny: "Izina ukoresha",
      },
      {
        id: 33,
        en: "Confirm Password",
        kny: "Emeza ijambo ry'ibanga",
      },
      {
        id: 34,
        en: "Contact info@infomaster.rw for support",
        kny: "Twandikirire kuri info@infomaster.rw",
      },
      {
        id: 35,
        en: "Domestic Workers",
        kny: "Abakozi",
      },
      {
        id: 36,
        en: "View More",
        kny: "Reba Byose",
      },
      {
        id: 37,
        en: "Students",
        kny: "Abanyeshuri",
      },
      {
        id: 38,
        en: "Properties",
        kny: "Imitungo",
      },
      {
        id: 39,
        en: "Staff",
        kny: "Abakozi",
      },
      {
        id: 40,
        en: "Expenses",
        kny: "Ibyaguzwe",
      },
      {
        id: 41,
        en: "Profile",
        kny: "Umwirondoro",
      },
      {
        id: 42,
        en: "Logout",
        kny: "Sohoka",
      },
      {
        id: 43,
        en: "Reports",
        kny: "Raporo",
      },
      {
        id: 44,
        en: "National ID / Passport",
        kny: "Indangamuntu / Pasiporo",
      },
      {
        id: 45,
        en: "Change password",
        kny: "Hindura ijambobanga",
      },
      {
        id: 46,
        en: "Agriculture",
        kny: "Ubuhinzi",
      },
      {
        id: 46,
        en: "Modules",
        kny: "Modire"
      },
      {
        id: 47,
        en: "services",
        kny: "serivisi"
      },

    ],
    schools: [
      {
        id: 1,
        name: "King David Academy",
        image: "king.jpg",
      },
      {
        id: 2,
        name: "Rwanda Coding Academy",
        image: "rca.png",
      },
      {
        id: 3,
        name: "ES Kanombe / EFOTEC",
        image: "efotec.jpg",
      },
    ],
    farms: [
      {
        id: 1,
        name: "Kanombe ICC Farm",
        image: "pou1.jpg",
      },
      {
        id: 2,
        name: "Munenega farm",
        image: "pou2.jpg",
      },
      {
        id: 3,
        name: "Mugisha farm",
        image: "pou3.jpg",
      },
    ],
    workers: [
      {
        id: 1,
        name: "Uwera Clarisse",
        image: "av.png",
      },
      {
        id: 2,
        name: "Kalisa Emmanuel",
        image: "av.png",
      },
      {
        id: 3,
        name: "Eric Kanamugire",
        image: "av.png",
      },
    ],
    techs: [
      {
        id: 1,
        name: "Eng. Mugabo Faustin",
        image: "pro1.jpg",
      },
      {
        id: 2,
        name: "Eng. Kalisa Emmanuel",
        image: "pro1.jpg",
      },
      {
        id: 3,
        name: "Eng. Eric Kanamugire",
        image: "pro1.jpg",
      },
    ],
    ismodules: [],
    imodules: [
      {
        id: 1,
        name: "Education",
        image: "edu.svg",
        cover: "edu.jpg",
        code: "IEDU",
      },
      {
        id: 2,
        name: "PSF",
        image: "psf.svg",
        cover: "psf.jpg",
        code: "IPSF",
      },
      {
        id: 3,
        name: "Domestic Workers",
        image: "dw.svg",
        cover: "dw.jpg",
        code: "IDW",
      },
      {
        id: 4,
        name: "Poultry",
        image: "pou.svg",
        cover: "pou.jpg",
        code: "IPA",
      },
      {
        id: 5,
        name: "Ejo Heza",
        image: "ejo.svg",
        cover: "ejo.jpg",
        code: "IEH",
      },
      {
        id: 6,
        name: "RNIT",
        image: "rnit.svg",
        cover: "rnit.jpg",
        code: "IRNIT",
      },
      {
        id: 7,
        name: "Trucks",
        image: "trucks.svg",
        cover: "trucks.jpg",
        code: "ITR",
      },
      {
        id: 8,
        name: "Technicians",
        image: "tec.svg",
        cover: "tech.jpg",
        code: "IMT",
      },
    ],
    mymodules: [
      {
        id: 1,
        name: "Education",
        image: "edu.svg",
      },
      {
        id: 2,
        name: "PSF",
        image: "psf.svg",
      },
      {
        id: 4,
        name: "Poultry",
        image: "pou.svg",
      },
      {
        id: 5,
        name: "Ejo Heza",
        image: "ejo.svg",
      },
      {
        id: 6,
        name: "RNIT",
        image: "rnit.svg",
      },
    ],
    countries: [],
    isLoading: false,
    showSANav: true,
    apiUploadUrl: "",
  },
  getters: {

  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    countries,
    modules,
    locations,
    profile,
    productCategories,
    cooperatives,
    files,
    products,
    orders
  }
})
